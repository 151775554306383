import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/default/DefaultTemplate.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const LandingHero = makeShortcode("LandingHero");
const StatsSection = makeShortcode("StatsSection");
const Section = makeShortcode("Section");
const Text = makeShortcode("Text");
const ResidentialServicesCTA = makeShortcode("ResidentialServicesCTA");
const CommercialServicesCTA = makeShortcode("CommercialServicesCTA");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <LandingHero on="page-home" mdxType="LandingHero" />
    <StatsSection on="page-home" mdxType="StatsSection" />
    <Section on="page-home" mod="bg-gray-25" pattern="feature" mdxType="Section">
  <Text as="h4" mod="mt-8 mb-6 md:mt-10 md:mb-8 xl:mt-12 xl:mb-10" pattern="title" mdxType="Text">
    Show Us Your Plan, We’ll Show You a Way
  </Text>
  <Text as="p" mod="mb-4 md:mb-5 xl:mb-6" mdxType="Text">
    Having a design idea is one thing, but executing a structure that’s safe,
    sound, and built to last is another thing entirely. We specialize in making
    abstract visions into feasible plans, and we’d love to hear about yours.
  </Text>
  <Text as="p" mdxType="Text">
    Signing on with HH Consulting means you get a team of engineers and
    consultants ready to tackle challenges along with you. From complimentary
    site visits and helping you submit plans to finding you trusted
    professionals in other disciples, our services extend far beyond the
    structural plans we provide.
  </Text>
    </Section>
    <ResidentialServicesCTA id="#services" on="page-home" mdxType="ResidentialServicesCTA" />
    <CommercialServicesCTA on="page-home" mdxType="CommercialServicesCTA" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      